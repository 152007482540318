.content_select[data-v-1f3aa608] {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.textColor[data-v-1f3aa608] {
  margin: 0 6px;
  cursor: pointer;
  font-size: 14px;
}
.selected[data-v-1f3aa608] {
  color: #17A2B8;
}
.drawerHead[data-v-1f3aa608] {
  display: flex;
  justify-content: space-between;
}
.cosName[data-v-1f3aa608] {
  cursor: pointer;
}
.cosName[data-v-1f3aa608]:hover {
  color: #17A2B8;
}
.page_container[data-v-1f3aa608] {
  float: right;
  margin-top: 10px;
}
